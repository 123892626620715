@import "./configs/color.css";
@import url('https://fonts.googleapis.com/css?family=Dosis');

.react-date-picker__wrapper {
    font-family: 'Dosis', sans-serif !important;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.1em solid  $secundario;
    border-radius: 1em;
    box-shadow:inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
    height: calc(2.25rem + 4px);
}
.is-invalid .react-date-picker__wrapper {
    border: 0.1em solid  $corinto;
}
.react-date-picker__wrapper input{
    box-shadow:inset 0 3px 0 0 rgba(0, 0, 0, 0) !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
}
.react-date-picker
{
    width: 100%;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
}
.react-calendar{
    font-size: 13px;
    font-family: 'Dosis', sans-serif !important;
}
.react-calendar__tile--active {
    background: $primario;
    color: white;
}


.react-date-picker__calendar {
    max-width: 80vw;
    position: absolute;
}
.react-date-picker__inputGroup__input{
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    font-weight: 500;
}


.react-date-picker__button:enabled:hover svg g, .react-date-picker__button:enabled:focus svg g {
    stroke: $primario;
}
.react-date-picker__button svg g, .react-date-picker__button svg g {
    stroke: $gris;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: $primario;
}
