.card-head-dashboard{
    background: #387CD0;
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    text-align: left;
}

.card-textoCantidad{
    margin-top: 15px;
}

.text-cantidad-card-first{
    color: rgb(41, 80, 128) !important;
    margin-bottom: initial;
}

.text-text-card-first{
    color: rgb(108, 145, 66) ;
}

.contenedor-card-texto{
    padding-right: 40px;
}

.imagen-card-dashboard{
    height: 100px;
    width: 100px;
}

.imagen-logo-dashboard{
    width: 200px;
    height: 75px;
}

.imagen-card-ventas-area-dashboard{
    height: 100px;
    width: 100px;
}

.card-vencidos-dashboard{
    height: 100px;
    width: 100px;
}

.color-cantidad-por-vencer-2{
    color: #387CD0 !important;
}

.color-cantidad-por-vencer-1{
    color: orange;
}

.color-cantidad-vencidos{
    color: red;
}