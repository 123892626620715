.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login{
    background: url('../../../../assets/img/back_login.png') #e5f5fd ;
    background-color:#e5f5fd;
    background-repeat: no-repeat;
    position: fixed;
    background-size: cover;
}
.login-container{

    width: 80vw;
}
.login-form{
    align-items: center;
    justify-content: end;
    align-self: center;
    opacity: 0;
    height: 60%;
    padding-right: 1em;
}
.login-form img{
     width: 85%;
}
.expand{
    animation: expandForm 0.35s ease forwards;
}
.collapsed{
    width: 0px !important;
    opacity: 0;
}
.visible{
    visibility: hidden;
    animation-duration: .2s;
    animation-name: expand;
    animation-delay: .5;
    animation-fill-mode: forwards;
}
.invisible{
    display: none !important;
}
.login_formulario{
    height: 400px;
    width: 360px;
    justify-content: center;
    z-index: 10;
    align-content: center;
    background: #0075d1;
    -webkit-box-shadow: 19px 21px 72px -27px rgba(77,77,77,1);
    -moz-box-shadow: 19px 21px 72px -27px rgba(77,77,77,1);
    box-shadow: 19px 21px 72px -27px rgba(77,77,77,1);
}
.login_logo img {
    width: 250px;
}

.login-form .panel-body{
    width: 350px;
}

.ver_isotipo{
    display: none !important;
}

@media (min-width: 1222px){
    @keyframes expand {
        0% {
            opacity: 0;
        }
        75%{
            visibility: visible;
            opacity: 0;
            width: 0px;
        }
        100%{
            opacity: 1;
            width: 350px;
        }
    }
    @keyframes expandForm{
        0%{
            opacity: 0;
            width: 0;
        }
        100%{
            opacity: 1;
            width: 700px;
        }
    }
}
@media (max-width: 1221px) {
    .ver_isotipo{
        display: block !important;
    }
    .login-container{
    }
    .login{
        overflow-y: auto;
    }
    .login-form{
        display: none !important;
        height: 0px;
    }
}
