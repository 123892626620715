@import './configs/color.css';

@import url('https://fonts.googleapis.com/css?family=Dosis');
input
{
  box-shadow:inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
}

.form-control {
    border-radius: 1em;
    border: 0.1em solid #aacfda;
}

.form-control:focus {
    border: 0.1em solid $celeste;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.form-control:active {
    border: 0.1em solid $celeste;
}

.form-group label {
    margin-top: 0px;
    font-weight: bold;
    font-size: 0.8rem;
    color: $gris-dark;
}

.label-radio {
    padding: 0 10px;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: $corinto;
}
.invalid-feedback {
    display: none;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $corinto;
}


/* Configuración de selects*/

.Select-control{
    border-radius: 1em;
    border: 0.1em solid $gris;
    height: calc(2.25rem + 4px);
}
.Select-menu-outer {
    display: block !important;
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
  background-color: #fff;
  border: 1px solid $gris !important;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.Select-option:last-child {
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
  border-bottom: 1px solid $gris !important;
}
.Select-menu{
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
    border-bottom: 1px solid $gris !important;
}
.Select-option.is-selected {
  color: $gris;

  background-color: $verde !important;
  /* Fallback color for IE 8 */
  /* background-color: rgba(0, 126, 255, 0.04); */
}
.Select-option:hover {
    background-color: $verde !important;
    color: $gris !important;
}
.Select.is-searchable.is-focused:not(.is-open) > .Select-control {
    border: 0px;
    border-color: $gris !important;
}
.Select-value{
    font-weight: normal !important;
    color: #aaa !important;
    padding-left: 10px !important
}
.select-reset > div{
    background-color: transparent !important;
}


.Select-control:hover{
    -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, .06);
}
.Select-option.is-focused{
    background-color: white;
}
/*Configuración datepicker*/
.SingleDatePickerInput__withBorder {
    border-radius: 1em !important;
    border: 0.1em solid $secundario;
}
.DateInput{
    background: transparent !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: $primario;
    border: 1px double $primario;
    color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight{
    display: none;
}
.DateInput_input__focused {
    border-radius: 1em !important;
}
.DateInput_input{
    border-radius: 1em !important;
    font-size: 1rem !important;
}
.is-invalid .SingleDatePickerInput{
    border-color: $gris;
}

/* Radio buttons*/
.form-check label{
    margin-right: 4px !important;
}

/*React-Select v2.1.1*/
.react-select__menu{
    border-bottom-left-radius: 1em !important;
    border-bottom-right-radius: 1em !important;
    margin: 0px !important;

    background: white !important;
    color: $gris !important;

}
.react-select__single-value{
    font-family: 'Dosis', sans-serif !important;
    font-weight: normal !important;
}
.react-select__control--is-disabled{
    background-color: #e9ecef !important;
    opacity: 1 !important;
}
.react-select--is-disabled{
    background-color: #e9ecef !important;
    opacity: 1 !important;
}
.select-reset > div .react-select__menu{
    background: white !important;
}
.react-select__control{
    border-color: transparent !important;
    background: transparent !important;
    box-shadow:inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
}
.react-select__control--is-focused{
    border: transparent !important;
}
.react-select__value-container{
    color: $gris !important;
}
.react-select__option{
    font-family: 'Dosis', sans-serif !important;
    font-weight: normal !important;
}
.react-select__option:active{
    background: #a6cec2 !important;
}
.react-select__menu-list:first-child{
    border-top-left-radius: 1em !important;
    border-top-right-radius: 1em !important;
}

.react-select__menu-list:last-child{
    border-bottom-left-radius: 1em !important;
    border-bottom-right-radius: 1em !important;
}
/*Select normal estilos*/
select{
    -webkit-appearance: menulist !important;
    border-radius: 1em !important;
}
select {
    border-radius: 1em;
}
/*Colores del radiobutton*/
input[type='radio'] {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: none;
    /* background: $verde; */
    border: 3px solid $gris;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type="radio"]:checked:before {
    background: white;

}

input[type="radio"]:checked {
  border-color: $verde;
  background: $verde;
}
/*  Checkbox */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */
 .c-checkbox,
 .c-radio {
   margin-right: 4px;
 }
 .c-checkbox *,
 .c-radio * {
   cursor: pointer;
 }
 .c-checkbox input,
 .c-radio input {
   opacity: 0;
   position: absolute;
   margin-left: 0 !important;
 }
 .c-checkbox span,
 .c-radio span {
   position: relative;
   display: inline-block;
   vertical-align: top;
   width: 20px;
   height: 20px;
   border: 1px solid #ccc;
   text-align: center;
 }
 .c-checkbox span:before,
 .c-radio span:before {
   margin-left: 1px;
 }
 .c-checkbox:hover span,
 .c-radio:hover span {
   border-color: $gris;
 }
 .form-inline .c-checkbox span, .form-inline
 .c-radio span {
   margin-left: 0;
 }
 .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
 .c-radio.c-checkbox-rounded span,
 .c-radio.c-radio-rounded span {
   border-radius: 500px;
 }

 /* override for radio */
 .c-radio span {
   border-radius: 500px;
 }

 /* the icon */
 .c-checkbox span:before,
 .c-radio span:before {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   text-align: center !important;
   font-size: 12px;
   line-height: 18px;
   vertical-align: middle;
 }

 /* Checked state */
 .c-checkbox,
 .c-radio {
   /* override for radio */
   /* Disable state */
   /* override for radio */
 }
 .c-checkbox input[type=checkbox]:checked + span:before,
 .c-checkbox input[type=radio]:checked + span:before,
 .c-radio input[type=checkbox]:checked + span:before,
 .c-radio input[type=radio]:checked + span:before {
   color: white;
   opacity: 1;
   transition: color .3s ease-out;
 }
 .lectura input[type=checkbox]:checked + span:before,
 .lectura input[type=radio]:checked + span:before,
 .lectura input[type=checkbox]:checked + span:before,
 .lectura input[type=radio]:checked + span:before {
   color: $gris;
   opacity: 1;
   transition: color .3s ease-out;
   cursor: initial;
 }
 .c-checkbox input[type=checkbox]:checked + span,
 .c-checkbox input[type=radio]:checked + span,
 .c-radio input[type=checkbox]:checked + span,
 .c-radio input[type=radio]:checked + span {
   border-color: $gris;
   background-color: $primario;
 }
 .lectura input[type=checkbox]:checked + span,
 .lectura input[type=radio]:checked + span,
 .lectura input[type=checkbox]:checked + span,
 .lectura input[type=radio]:checked + span {
   border-color: #fff !important;
   background-color: $primario !important;
   cursor: initial;
 }
 .lectura{
   cursor: initial !important;
 }
 .lec{
     border: 0px solid #eff3f6 !important;
 }
 .c-checkbox input[type=radio]:checked + span,
 .c-radio input[type=radio]:checked + span {
   background-color: #fff;
 }
 .c-checkbox input[type=radio]:checked + span:before,
 .c-radio input[type=radio]:checked + span:before {
   color: $gris;
 }
 .c-checkbox input[type=checkbox]:disabled + span,
 .c-checkbox input[type=radio]:disabled + span,
 .c-radio input[type=checkbox]:disabled + span,
 .c-radio input[type=radio]:disabled + span {
    background: $primario;
   background: $primario;
 }
 .c-checkbox input[type=radio]:disabled + span,
 .c-radio input[type=radio]:disabled + span {
    background: $primario;
 }
 .c-checkbox input[type=radio]:disabled + span:before,
 .c-radio input[type=radio]:disabled + span:before {
    background: $primario;
 }

input[type='checkbox'] {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 3px solid gray;
}

input[type='checkbox']:before {
    display: block;
}

input[type="checkbox"]:checked:before {
    content: '✔';
    position: absolute;
    height: 15px;
    width: 15px;
    top:0;
    left: 2px;
    color: #ffffff;
    display: inline-block;
    font-size: .8em;
    background: $primario;

}

input[type="checkbox"]:checked {
    background: $primario;
  border-color: white;
}
.role {
    margin-right: 80px;
    margin-left: 20px;
    font-weight: normal;
}

.checkbox label {
    margin-bottom: 20px !important;
}

.roles {
    margin-bottom: 40px;
}

label{
    color: $gris !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  background: #fff !important;
}

.custom-error{
    font-weight: bold;
    font-style: italic;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #9e0b0f;
}
