@import '../../../../style/configs/color.css';
/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar-wrapper{
    position: fixed;
    width: 100%;
    z-index: 50;
}
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  background-color: #fff;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.navbar{
    padding-bottom: 0.5rem !important;
    background: #579acb !important;
    background: -moz-linear-gradient(left, #0075d1 0%, #579acb 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, #0075d1), color-stop(100%, #579acb)) !important;
    background: -webkit-linear-gradient(left, #0075d1  0%, #579acb 100%) !important;
    background: -o-linear-gradient(left, #0075d1  0%, #579acb 100%) !important;
    background: -ms-linear-gradient(left, #0075d1  0%, #579acb 100%) !important;
    background: linear-gradient(to right, #0075d1  0%, #579acb 100%)  !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0075d1', endColorstr='#579acb', GradientType=1 ) !important;
}
@media only screen and (min-width: 768px) {
  .topnavbar .navbar-header {
    width: 220px;
    text-align: center;
  }
  .topnavbar .navbar-header .navbar-brand {
    width: 100%;
  }
}

.topnavbar {
  position: fixed;
  width: 100%;
}
.topnavbar .navbar-header {
  background-color: transparent;
  background-image: -webkit-linear-gradient(left, #fff 0%, #fff 100%);
  background-image: -o-linear-gradient(left, #fff 0%, #fff 100%);
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF', GradientType=1);
}
@media only screen and (min-width: 768px) {
  .topnavbar .navbar-header {
    background-image: none;
  }
}
.topnavbar .navbar-header {
  position: relative;
  z-index: 11;
}
.topnavbar .navbar-header .navbar-brand {
  padding: 0;
}
.topnavbar .navbar-header .brand-logo > img,
.topnavbar .navbar-header .brand-logo-collapsed > img {
  margin: 0 auto;
}
.topnavbar .navbar-header .brand-logo {
  display: block;
  padding: 10px 15px;
}
.topnavbar .navbar-header .brand-logo-collapsed {
  display: none;
  padding: 6px 15px;
}
.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  color: #000;
}
.topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .open > a:focus {
  color: #cccccc;
}
.topnavbar .navbar-nav > .active > a, .topnavbar .navbar-nav > .active > a:hover, .topnavbar .navbar-nav > .active > a:focus,
.topnavbar .navbar-nav > .open > a,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .open > a:focus {
  background-color: transparent;
}
.topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  font-size: 16px;
  line-height: 85px;
  color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.sidebar-toggle {
    position: absolute !important;
    margin-top: 13px;
    margin-left: 15px;
    /*right: 0;*/
    color: white !important;
    /*z-index: 3001;*/
}
.bm-menu {
    background: $gris;
}
@media only screen and (min-width: 768px) {
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    color: #515253;
  }
}


.nav-wrapper {
  background-color: transparent;
  /*height: 48px !important;*/
  display: flex;
  align-items: center;
}
.nav-wrapper .nav.navbar-nav {
  float: left;
}
.nav-wrapper .nav.navbar-nav.navbar-right {
  float: right;
}
.nav-wrapper .nav > li {
  position: static;
  float: left;
}
.nav-wrapper .navbar-nav .open .dropdown-menu {
  position: absolute;
  background-color: white;
  left: 0px;
  right: 0px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.navbar-nav {
  margin: 0 !important;
}

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 85px;
  z-index: 9001;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 1px solid #e1e2e3;
}
.topnavbar .navbar-form .form-group {
  height: 100%;
  width: 100%;
}
.topnavbar .navbar-form .form-control, .topnavbar .navbar-form body .bootstrap-tagsinput, body .topnavbar .navbar-form .bootstrap-tagsinput {
  height: 100%;
  border: 0;
  border-radius: 0;
  width: 100%;
}
.topnavbar .navbar-form.open {
  top: 0;
}
.topnavbar .navbar-form .has-feedback .form-control-feedback {
  height: 30px;
  cursor: pointer;
  top: 50%;
  margin-top: -15px;
  line-height: 30px;
  margin-right: 10px;
  color: #c1c2c3;
  font-size: 1.5em;
  pointer-events: auto;
}
@media only screen and (min-width: 768px) {
  .topnavbar .navbar-form {
    left: 220px;
  }
}

@media only screen and (min-width: 768px) {
  .topnavbar {
    border: 0;
    background-color: #fff;
    background-image: -webkit-linear-gradient(left, #fff 0%, #fff 100%);
    background-image: -o-linear-gradient(left, #fff 0%, #fff 100%);
    background-image: linear-gradient(to right, #fff 0%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF', GradientType=1);
  }
  .topnavbar .navbar-header {
    background-image: none;
    background-repeat: no-repeat;
    filter: none;
  }
  .topnavbar .navbar-nav > a {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .topnavbar .navbar-nav > .open > a, .topnavbar .navbar-nav > .open > a:hover, .topnavbar .navbar-nav > .open > a:focus {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    color: #515253;
  }
  .topnavbar .navbar-nav > li > a:hover, .topnavbar .navbar-nav > li > a:focus,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .open > a:focus {
    color: #cccccc;
  }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: static;
  }

  .nav-wrapper {
    position: relative;
    z-index: 10;
  }
  .nav-wrapper:before, .nav-wrapper:after {
    content: " ";
    display: table;
  }
  .nav-wrapper:after {
    clear: both;
  }
  .nav-wrapper .nav > li {
    position: relative;
  }
  .nav-wrapper .navbar-nav .open .dropdown-menu {
    left: auto;
    right: auto;
  }
  .nav-wrapper .navbar-nav.navbar-right .open .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none;
  }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block;
  }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px;
  }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px;
  }
}
.topnavbar-wrapper img{
    max-height: 48px;
}

.perfil{
    color: white;
}

.dashboard-blank{
    background: white;
    border-radius: 7rem;
    padding: 15px;
    -webkit-box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
    box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
}
.dashboard-blank img{
    width: 70%;
}
