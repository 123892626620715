$color-title: #721c24;
$color-navbar: #0075d1;


$primario: #0075d1;
$secundario: #aacfda;
$rosado: #e24647;
$verde: #88A530;
$verde-claro: #B5CD6A;
$celeste: #509aae;
$celeste-dark: #175073;
$gris: #717981;
$corinto: #9e0b0f;
$gris-dark: #516060;
$gris-celeste: #e5f5fd;
$fondo: #e5f5fd;

$letra-primario: #175073;

.celeste-dark{
    color: $celeste-dark;
}
