@import './configs/color.css';
button:focus{
    outline: 1px auto;
    outline-color: transparent;
}
button img{
    margin-right: 5px;
}
.btn-estadocuenta{
    width: 170px;
    height: 37px;
}
.btn-normal {
    width: auto !important;
    min-width: 0 !important;
}
.btn-xs{
    font-size: 1em;
    height: 30px;
    padding: 0.1rem 0.75rem;
    min-width: 10px !important;
}
.btn {
    border-radius: 2rem;
    min-width: 120px;
    font-weight: bold;
    text-transform: uppercase;
}
.btn-with-all{
    border-radius: 0px !important;
    width: 100% !important;
    -webkit-box-shadow: inset -1px 11px 19px -2px rgba(217,214,217,0.75);
    -moz-box-shadow: inset -1px 11px 19px -2px rgba(217,214,217,0.75);
    box-shadow: inset -1px 11px 19px -2px rgba(217,214,217,0.75);
}
.btn-force {
    border-radius: 2rem !important;
    min-width: 120px !important;
    font-weight: bold !important;
}
.btn-link{
    background-color: transparent !important;
    color: $verde;
}
.btn-link:hover{
    background-color: transparent !important;
    color: $verde;
}
.btn-link:active{
    background-color: transparent !important;
    color: $verde;
}

.btn-gray{
    background-color: $gris !important;
    color: $verde;

}
.btn-gray:hover{
    background-color: $gris !important;
    color: $secundario;
}
.btn-gray:active{
    background-color: $gris !important;
    color: $verde;
}

.btn-white{
    background-color: white !important;
    color: $verde;

}
.btn-white:hover{
    background-color: $verde !important;
    color: white;
}
.btn-white:active{
    background-color: white !important;
    color: $verde;
}
/* ESTILO DE BOTON PRIMARIO*/
.btn-primary{
    background-color: $primario !important;
    color: white !important;
    border: 0px !important;
}
.btn-primary:hover{
    background-color: $verde !important;
    border: 0px !important;
}
.btn-primary:active{
    background-color: $secundario !important;
    border: 0px;
}
.btn-primary:focus{
    background-color: $primario !important;
    border: 0px;
}

/* ESTILO DE BOTÓN SECUNDARIO*/
.btn-secondary{
    background-color: $secundario !important;
    color: $primario !important;
    border: 0px !important;
}
.btn-secondary:hover{
    background-color: $verde !important;
    color: white !important;
    border: 0px !important;
}
.btn-secondary:active{
    background-color: $primario !important;
    border: 0px !important;
}
.btn-secondary:focus{
    background-color: $secundario !important;
    border: 0px !important;
}

/* ESTILO DE BOTÓN SECUNDARIO*/
.btn-fondo{
    background-color: $fondo !important;
    color: $primario;
    border: 2px solid $secundario;
}
.btn-fondo:hover{
    background-color: $verde !important;
    color: white !important;
    border: 0px !important;
}
.btn-fondo:active{
    background-color: $primario !important;
    border: 0px !important;
    color: white;
}
.btn-fondo:focus{
    background-color: $primario !important;
    border: 0px !important;
    color: white !important;
}



.btn-danger{
    background-color: $corinto;
    border-color: transparent;
    color: #ffffff;
}
.btn-danger:hover{
    background-color: $secundario !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-danger:active{
    background-color: $corinto !important;
    border-color: transparent !important;
    color: #ffffff;
}

.btn-success{
    background-color: $verde;
    border-color: transparent;
    color: white;
}
.btn-success:hover{
    background-color: $primario !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-success:active{
    background-color: $verde !important;
    border-color: transparent !important;
    color: white;
}



.btn-success-outline{
    background-color: white;
    color: $primario;
    border: 2px solid $verde;
}
.btn-success-outline:hover{
    background-color: $primario;
    border-color: transparent;
    color: white;
}
.btn-success-outline:active{
    background-color: white;
    color: $primario;
    border-color: $verde;
    border: 2px solid $verde;
}


.btn-rosado{
    background-color: $rosado;
    border-color: transparent;
    color: #ffffff;
}
.btn-rosado:hover{
    background-color: $secundario !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-rosadoo:active{
    background-color: $rosado !important;
    border-color: transparent !important;
    color: #ffffff;
}


.btn-celeste{
    background-color: $celeste;
    border-color: transparent;
    color: #ffffff;
}
.btn-celeste:hover{
    background-color: $secundario !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-celeste:active{
    background-color: $corinto !important;
    border-color: transparent !important;
    color: #ffffff;
}


.btn-blanco{
    background-color: white;
    border-color: transparent;
    color: $primario;
}
.btn-blanco:hover{
    background-color: $primario !important;
    border-color: transparent !important;
    color: white;
}
.btn-blanco:active{
    background-color: white;
    border-color: transparent;
    color: $primario;
}

.btn-verde{
    background-color: $verde;
    border-color: transparent;
    color: white;
}
.btn-verde:hover{
    background-color: $verde-claro !important;
    border-color: transparent !important;
    color: $primario;
}
.btn-verde:active{
    background-color: $verde-claro;
    border-color: transparent;
    color: $primario;
}

.btn-tabla{
    border-radius: 5px;
    height: 28px !important;
    width: 28px !important;
    font-weight: bold;
    text-transform: uppercase;
}
/* .btn-info{
  background-color: $color-amarillo;
  border-color: transparent !important;
  color: white;
}
.btn-info:hover{
  background-color: $color-amarillo-dark;
  border-color: transparent !important;
}
.btn-info:active{
  background-color: $color-amarillo-dark;
  border-color: transparent !important;
}
.padding-0{
    padding: 0 !important;
}
.btn a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 6px 12px;
} */
.btn-s {
    border-radius: 2rem;
    min-width: 121px;
    height: 28px;
    font-weight: bold;
    padding: 0.315rem 0.75rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: .8rem;
    line-height: 1.5;
}
.btn-s:not(:disabled):not(.disabled) {
    cursor: pointer;
}

