@import "./configs/color.css";
@import url('https://fonts.googleapis.com/css?family=Dosis');
.react-bs-container-header {
    font-weight: bold;
    font-family: 'Dosis', sans-serif !important;
    color: $celeste-dark;
    font-size: 1em !important;
    text-transform: uppercase;
    background: $secundario;
    border: 0px !important;
}
.react-bs-container-header  th{
    font-size: 1.2em !important;
}
.header-tabla{
    font-family: 'Dosis', sans-serif !important;
    height: 43px !important;
    font-size: 1em;
    text-transform: uppercase !important;
    vertical-align: middle !important;
    color: $celeste-dark !important;
    background: $secundario !important;
}

.table-bordered th, .table-bordered td {
    border: 0 !important;
}
.react-bs-container-body tr:nth-child(even){
    background-color: #ebebeb;
}
.no-striped .react-bs-container-body tr:nth-child(even){
    background-color: #ebebeb;
}
.con-hijos .react-bs-container-body tr:nth-child(even){
    background-color: white;
}
.con-hijos .react-bs-container-body > tr:nth-child(even) > td{
    padding: 0;
}
.con-hijos .react-bs-container-body tr:nth-child(4n+3){
    background-color: #ebebeb !important;
}

.react-bs-container-body{
    font-weight: bold;
    color: $gris;
}
.react-bs-table-bordered{
    border: none !important;
    border-radius: 0 !important;
}
.react-bs-table-tool-bar {
    background-color: $gris;
    /* border-radius: 5px; */
    padding: 8px 10px 10px 10px;
}
.react-bs-table{
    font-style: normal !important;
    /*border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border: 3px solid $gris !important;
    border-top: 0px !important; */

}
.td-column-string-example {
    width: 250px !important;
}
.td-header-string-example{
    width: 250px !important;
}
.td-column-descripcion-gastos {
    width: 50% !important;
    min-width: 250px !important;
}
.td-header-descripcion-gastos{
    width: 50% !important;
    min-width: 250px !important;
}
.react-bs-table-container {
    font-size: 13px !important;
    .react-bs-table table td, .react-bs-table table th {
      text-overflow: unset;
      overflow-wrap: break-word;
      width: 130px;
    }
  }
  .strikeout td:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 2px solid red;
    width: 100%;
  }

/* Paginaciones */
.page-item:last-child .page-link{
    font-style: normal !important;
}

.page-item:first-child .page-link {
    font-style: normal !important;
}
.page-link{
    font-style: italic !important;
    color: $primario;
}

.page-item.active .page-link {
    z-index: 1;
    border-color: transparent;
    color: $gris-dark;
    background-color: $verde;
}
.sin-borde-top {
    border-top: 0px !important;
}

/* .table-hover > tbody > tr:hover{
    background-color: inherit;
}
.table-hover> tbody> tr:hover:nth-child(even){
    background-color: #ebebeb;
} */


.tabla-70 {
    .react-bs-container-body{
        max-height: 70vh !important;
    }
}
.tabla-5p .react-bs-container-body {
    max-height: 31vh !important;
}
.tabla-103{
    .react-bs-container-body{
        max-height: 135vh !important;
    }
}
.resumen-cf{
    .react-bs-table {
        font-style: italic;
        /* border-bottom-left-radius: 20px !important; */
        /* border-bottom-right-radius: 20px !important; */
        /* border: 3px solid #ebebeb !important; */
        border: 0px !important;
    }
    .react-bs-container-header {
        border-top-left-radius: 1em !important;
        border-top-right-radius: 1em !important;
    }
}
/**
* Para quitar el scroll horizontal de la tabla
*/
.tabla-auto{
    .table-bordered {
        min-width: auto !important;
    }
}

/*
    Estilos de la tabla fija
*/
.tabla-adentro{
    padding-left: 20% !important;
}
.tabla-adentro .react-bs-container-header table thead {
    text-transform: none !important;
    color: $celeste-dark;
    background-color: $fondo;
}
.tabla-adentro .react-bs-container-body tr:nth-child(even){
    background-color: #ebebeb !important;
}
.tabla-adentro .react-bs-container-body tr:nth-child(odd){
    background-color: white !important;
}
.header-tabla{
    border: 0px;
    color: $celeste-dark;
    background: $secundario;
}
.background-row-selected{
    background-color: #ebebeb;
}

.tabla-gris .react-bs-container-header {
    background: $gris !important;
    color: $primario !important;
    border: 0px !important;
}

.tabla-gris .react-bs-table{
    border: 0px !important;

}
.tabla-gris .header-tabla{
    background: $gris !important;
    color: $primario !important;
    height: 43px !important;
}
@media only screen and (max-width: 576px) {
    .td-column-descripcion-gastos {
        width: 250px !important;
    }

    .tabla-103{
        .react-bs-container-body{
            max-height: 60vh !important;
        }
    }
}

tr.clickable{
    cursor: pointer;
}

.borde-derecho .react-bs-table{
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border: 3px solid $gris !important;
}

.borde-derecho .react-bs-container-header {
    border-top-right-radius: 1em !important;
}
.background-noleido{
    background: #b5d587 !important;
}
