@import "../../../../style/configs/color.css";
.sidebar {
    position: fixed;
    top: 61px;
    width: 160px;
    bottom: 0;
    z-index: 500;
    height: calc(100% - 61px);
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: bold;
    transition: width linear 0.2s;
    background-color: white;
    color: $celeste-dark !important;
    -webkit-box-shadow: 12px 4px 23px -16px rgba(112,106,112,1);
    -moz-box-shadow: 12px 4px 23px -16px rgba(112,106,112,1);
    box-shadow: 12px 4px 23px -16px rgba(112,106,112,1);
    .img-activo {
        display: none;
    }
    .activo {
        /* color: #fff; */
        font-weight: bold;
        background-color: $fondo;
        font-weight: normal;
        border-right: 4px solid $verde;
        color: $verde !important;
        .img-activo {
            display: inline;
        }
        .img-inactivo {
            display: none;
        }
    }
    .activo-subitem{
        /* color: #fff; */
        font-weight: bold;
        background-color: $secundario;
        font-weight: normal;
        border-right: 4px solid $verde;
        .img-activo {
            display: inline;
        }
        .img-inactivo {
            display: none;
        }

    }
}
.sidebar-small{
    width: 60px !important;
}
.sidebar img.sub-item {
    margin-right: 5px;
}

.sidebar img {
    width: 30px;
}
.accordion__title:active{
    background-color: $fondo;
}
.sidebar-item {
    /* border-left: 4px solid transparent; */
    /* display: grid; */
    /* grid-template-columns: 35px 1fr; */
    align-items: center;
    padding: 4px 2px;
    color: $primario;
}
.sidebar-subitem{
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: center;
    padding: 4px 10px;
    background-color: $fondo;
    color: $primario;
    height: 40px;
}
.blue-hover{
    /* grid-template-columns: 35px 1fr; */
    align-items: center;
    /* padding: 4px 10px; */
}

.imagen-sidebar {
    img {
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1);
        border-radius: 50%;
        width: 90px;
    }
}
