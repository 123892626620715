/* @import "../../node_modules/react-select/dist/react-select.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "_override.css"; */

/* @import "../../node_modules/react-select/dist/react-select.css"; */
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "_override.css";
@import "configs/color";
@import "bootstrap_reset.css";
@import "_custom_global_styles.css";
@import "_datepicker_overrides.css";
@import "_custom_table.css";
@import "_custom_buttons.css";
@import "_custom_inputs.css";
@import "_custom_timepicker.css";
@import "_scrollbar.css";
@import "configs/nv3.css";
@import "_custom_datecat.css";
@import "card-ingreso.css";
@import "_custom_print.css";
/* @import "impresion.css"; */

@import url('https://fonts.googleapis.com/css?family=Dosis');

br {
    border-top: 3px solid #3f87a6;
    content: '';
    display: block;
    width: 100%;
    margin: 5px 0 15px 0;
}

.container {
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}

@media only screen and (max-width: 768px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }
}

/* .content-wrapper {
  position: absolute;
  top: 76px;
  width: 100%;
} */



h1,
h2,
h3 {
    font-family: 'Dosis', sans-serif;
    text-align: center;
}
.pt-15{
    padding-top: 21px !important;
}
.pt-18{
    padding-top: 18px !important;
}
.mt-15{
    margin-top: -17px;
}
.mt-40{
    margin-top: -43px !important;
}
/*Style preloader*/
.loader-container {
    min-height: 140px;
}

.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
}

.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/**/
.border-naranja {
    border-radius: 1em;
    border: 1px solid $primario;
}

.border-naranja-movil {
    border-radius: 0;
    border: 0;
}

/*Aplicar los estilos en movil*/
@media only screen and (max-width: 576px) {
    .border-naranja-movil {
        border-radius: 1em;
        border: 1px solid $primario;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 801px) {
    .sidebar-toggle {
        display: none !important;
    }

    .bm-burger-button {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }

    .sidebar {
        display: none !important;
    }

    .content-wrapper {
        left: 0 !important;
        width: 100%;
        padding: 5px 0 0;
    }
    .h85{
        height: auto !important;
        padding-bottom: 10px !important;
    }
}

.fixed-size-square-ganancias {
    display: table;
    width: 20px;
    height: 20px;
    background: $primario;
}

.fixed-size-square-costos {
    display: table;
    width: 20px;
    height: 20px;
    background: $rosado;
}

.horizontal-scroll {
    max-width: 100%;
    overflow-x: auto;
}

.over-h {
    overflow: hidden;
}

.num-alert {
    height: 25px;
    width: 25px;
    font-weight: bold;
    background-color: #9e0b0f;
    border-radius: 50%;
    color: white;
    text-decoration: none !important;
    z-index: 10;
    position: absolute;
    bottom: -8px;
    right: 0;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.btn .bubble-alert-danger {
    padding-left: 4px;
    height: 15px;
    width: 15px;
    padding-right: 4px;
    background-color: #9e0b0f;
    border-radius: 50%;
    color: white;
    text-decoration: none !important;
    z-index: 10;
    position: absolute;
    top: -8px;
    right: 10px;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.bubble-alert-danger {
    padding-left: 4px;
    height: 15px;
    width: 15px;
    padding-right: 4px;
    background-color: #9e0b0f;
    border-radius: 50%;
    color: white;
    text-decoration: none !important;
    z-index: 10;
    position: absolute;
    top: -8px;
    right: 10px;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.bubble-alert-success {
    padding-left: 4px;
    padding-right: 4px;
    background-color: $verde;
    border-radius: 50%;
    color: white;
    height: 15px;
    width: 15px;
    text-decoration: none !important;
    z-index: 10;
    position: absolute;
    top: -8px;
    right: 10px;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

@-webkit-keyframes flash {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash;
    animation-iteration-count: infinite;
}
.btn-tablet {
    display: flex;
    justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
    .btn-tablet {
        justify-content: space-between;
    }
}

