/* ESTILOS PARA LAS MODALES */
@import './configs/color.css';
@import url('https://fonts.googleapis.com/css?family=Dosis');

body,
html {
    font-family: 'Dosis', sans-serif;
    background-color: #e5f5fd;
    font-weight: bold;
}

.styles_modal__gNwvD {
    /* border-radius: 15px; */
    padding: 0px;
    max-width: 80% !important;
    min-width: 770px;
}
.content-wrapper {
    /* background-color: white; */
    position: absolute;
    padding: 15px;
    top: 61px;
    left: 160px;
    min-height: calc(100vh - 61px);
    right: 0;
    transition: left linear 0.2s;
}
.contenido-principal {
    max-width: 100%;
    -webkit-box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
    box-shadow: 0px 6px 20px 3px rgba(0,0,0,0.28);
    background-color: white;
}
.fondo-dashboard1{
    background: url('../assets/img/back_dashboard.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 91vh;
    width: 103%;
    margin-top: -15px;
    margin-left: -20px;
    margin-bottom: -15px;
    margin-right: -17px;
    @media (max-width: 991.98px) {
        height: 92vh;
    }
    @media (max-width: 575.98px) {
        height: 90vh;
        width: 100%;
        margin-top: -5px;
        margin-left: 0px;
        margin-bottom: 0;
        margin-right: 0;
    }

}
.menu-small-content-wrapper{
    left: 70px !important;
}

.styles_overlay__CLSq- {
    background: rgba(0, 0, 0, 0.45);
    z-index: 998 !important;
}
.custom-modal {
    background: #FFF;
    width: 100%;
}
.modal-width-50 {
    width: 50% !important;
}
.modal-width-40 {
    width: 40% !important;
}
.modal-header{
    font-weight: bold;
    text-transform: uppercase;
    color: $letra-primario;
    border: 0px;
}
.modal-container {
    /* border-radius: 10px; */
    border: 2px solid #E8EBEE;
    margin: 0;
    background-color: #FFFFFF;
}
.modal-body {
    height: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}
.modal-footer {
    padding: 15px 0;
    width: 100%;
}
.styles_closeIcon__1QwbI{
    fill: white;
}
.reset-caption{
    font-size: 1.1rem;
}

/*Estilos de borde contendor*/
.borde-superior {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 3px solid $gris;
    border-bottom: none;
}
.border-completo{
    border-radius: 20px !important;
    border: 3px solid $gris !important;
}
/* Estilos del card*/
.card{
    padding: 0px;
    border: 0px !important;
  }
  .card-header {
    padding: 0.3rem 0.15rem;
    background-color: white !important;
    border-bottom: 2px solid $primario !important;
    /* color: white !important; */
  }
  .card-header h3{
      /* color: white !important; */
  }
  .card-header:first-child {
    font-weight: bold;
    color: $celeste-dark !important;
  }
  .fondo-secundario{
      background: $secundario !important;
  }
  .fondo-gris{
      background: $gris !important;
  }
  .fondo-celeste{
      background: $celeste !important;
  }
  .card-text {
    font-size: 11px;
  }
  .borde-abajo{
    border-bottom: 1px solid $primario;
  }
h2{
    font-weight: bold !important;
    color: $celeste-dark !important;
}
h3 {
    font-weight: bold !important;
    color: $celeste-dark;
}
h1 {
    font-weight: bold !important;
    color: $celeste-dark;
}
h4{
    font-weight: bold !important;
    color: $celeste-dark;
}
h5{
    font-weight: bold !important;
    color: $celeste-dark;
}

h6{
    font-weight: bold !important;
    color: $celeste-dark;
}

.h1, .h2, .h3, .h4 {
    text-transform: uppercase !important;
    font-weight: bold !important;
    color: $celeste-dark !important;
    font-family: 'Dosis', sans-serif !important;
}
.h5{
    font-weight: bold !important;
}
.text-verde{
    color: $verde !important;
}
.text-primary{
    color: $celeste-dark !important;
}
.text-azul{
    color: $primario !important;
}
.text-secondary{
    color: $secundario !important;
}
.text-danger{
    color: $corinto !important;
}
.text-gris{
    color: $gris-dark !important;
}
.text-danger-dark{
    color: #e24647;
}
.text-rosado{
    color: $rosado;
}
.text-celeste{
    color: $celeste !important;
}
.text-verde-claro{
    color: $verde-claro !important;
}
.text-derecha{
    text-align: right !important;
}
.ver-movil{
    display: none;
}
.ver-movil-flex{
    display: none;
}
.ver-escritorio{
    display: block;
}
.ver-escritorio-cell{
    display: table-cell;
}
.ver-escritorio-flex{
    display: flex;
}
/*etiquetas globlase*/
.display-grid{
    display: none;
}
/*CONFIGURACIÓN DE POPOVERS*/
.popover-inner{
    border-radius: 10px !important;
}
.popover-header{
    color: white !important;
    background: $secundario;
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after{
    border-bottom-color: $secundario;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    border-bottom: 0px;
}
/*Estilos para tabs*/
.nav-tabs .nav-link.active{

    background: $primario;
    color: white !important;
}
.nav-tabs .nav-link.active h5{
    color: white !important;
}
.nav-tabs .nav-link {
    height: 100%;
    background: #ebebeb;
    text-transform: uppercase;
    color: $letra-primario !important;
}
.nav-tabs .nav-link h5 {
    font-size: 1.1em;
    font-weight: 600;
}
.nav-tabs{
    border-bottom: 0px !important;
}

.nav-tabs .nav-item{
    cursor: pointer;
}
.nav-link .active a:hover{
    color: white !important;
}
a.active.nav-link:hover {
    color: white;
}
.nav-item{
    cursor: pointer;
}
.tabs-caja-chica{
    li:nth-last-child(2) a{
        border-top-right-radius: 20px;
    }
    li:last-child a {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
}
.cursor-pointer{
    cursor: pointer;
}
.chart-container {
    position: relative;
    height:90px;
    width:100%;
}
@media (min-width: 1200px)
{
    .contenido-principal {
        max-width: 100%;
    }
}

@media only screen and (min-width: 768px){
    .np-r{
        padding-right: 0px !important;
    }
}

@media only screen and (max-width: 768px) {
    .card-body{
        padding-left: 0px;
        padding-right: 0px;
    }
    .label{
        margin:0px !important;
    }

}
@media only screen and (max-width: 576px) {
    .styles_modal__gNwvD{
        max-width: 95% !important;
    }
    .sin-padding{
        padding: 0 !important;
    }
    .modal-body {
        padding-left: 0px;
        padding-right: 0px;
    }
    .ver-movil{
        display: block !important;
    }
    .ver-movil-flex{
        display: flex !important;
    }
    .ver-escritorio-cell{
        display: none !important;
    }
    .display-grid{
        display: grid
    }
    .ver-escritorio{
        display: none !important;
    }
    .ver-escritorio-flex{
        display: none !important;
    }
    [class*="nav-tabs"] li a h5 {
        font-size: .6em !important;
    }
    .styles_modal__gNwvD {
        min-width: 0;
    }
    .borde-lateral{
        border: 0px !important;
    }
}

.bordes-izquierdos{
    border-radius: 20px 0 0 20px !important;
    border: 3px solid #ebebeb !important;
}

.bordes-derechos{
    border-radius: 0 20px 20px 0 !important;
    border: 3px solid #ebebeb !important;
}
.borde-extremo-y{
    border-bottom: 2px solid $primario;
}
.required-text {
    display: block;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #9e1d20;
}
/*Manejo de bordes*/
.sin-borde {
    border: 0 !important;
}
.borde-primario{
    border-color: $primario  !important;
}
.borde-gris{
    border-radius: 1em !important;
    border: 2px solid $gris-celeste !important;
}
.border-2x{
    border: 1px solid !important;
}
.rojo{
    color: #D05452 !important;
}
.violeta{
    color: #3F3456 !important;
}
.text-m{
    font-size: 1.1rem !important;
}
.texto-monedas{
    font-weight: bold !important;
    font-style: italic !important;
    font-size: 1.1rem !important;
    text-transform: uppercase !important;
    color: #D05452 !important;
}
.tachar{
    text-decoration:line-through !important;
}
.select-rosado{
    font-style: italic;
    font-size: 1.2em;
    color: $rosado;
    border: 0.1em solid $primario !important;
}
.icons-oc{
    font-size: 1.7rem !important
}
.ocultar{
    display: none !important;
}
.quit-uppercase {
    text-transform: initial !important;
}

.borde-lateral{
    border-left: 0.1em solid $gris ;
}

/*Manejo de estilos para los gallineros*/
.borde-radius{
    border-radius: 10px;
}

.imagen-inicio{
    /* background: url('../assets/img/backdashboard.png') white; */
    background-size: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    margin-left: -1rem;
    margin-right: -1rem;
}

.inicio-logo{
      max-width: 100vw;
}

.img-cont{
   height: 70%;
}
.img-cont2{
   height: 40%;
}
.flecha_fracciones{
    width: 20px;
    height: 35px;
}
.flex-1{
    flex: 1;
 }
 .flex-2{
    flex: 2;
 }
.dot{
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.background-danger{
    background: #eba65e !important;
}
.background-success{
    background: #b5d587 !important;
}
.tamanio-cuadrado{
    width: 90px !important;
}
.dot-naranja{
    background: #eba65e;
}
.dot-verde{
    background: #b5d587;
}
.background-secondary{
    background-color: $secundario !important;
}
.fondo-autorizacion{
    background: rgba(229,245,253,1);
    background: -moz-linear-gradient(top, rgba(229,245,253,1) 0%, rgba(170,207,218,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(229,245,253,1)), color-stop(100%, rgba(170,207,218,1)));
    background: -webkit-linear-gradient(top, rgba(229,245,253,1) 0%, rgba(170,207,218,1) 100%);
    background: -o-linear-gradient(top, rgba(229,245,253,1) 0%, rgba(170,207,218,1) 100%);
    background: -ms-linear-gradient(top, rgba(229,245,253,1) 0%, rgba(170,207,218,1) 100%);
    background: linear-gradient(to bottom, rgba(229,245,253,1) 0%, rgba(170,207,218,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5f5fd', endColorstr='#aacfda', GradientType=0 );
}
.fondo-inverso-navbar{
    background: rgba(170,207,218,1);
    background: -moz-linear-gradient(left, rgba(170,207,218,1) 0%, rgba(34,112,160,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(170,207,218,1)), color-stop(100%, rgba(34,112,160,1)));
    background: -webkit-linear-gradient(left, rgba(170,207,218,1) 0%, rgba(34,112,160,1) 100%);
    background: -o-linear-gradient(left, rgba(170,207,218,1) 0%, rgba(34,112,160,1) 100%);
    background: -ms-linear-gradient(left, rgba(170,207,218,1) 0%, rgba(34,112,160,1) 100%);
    background: linear-gradient(to right, rgba(170,207,218,1) 0%, rgba(34,112,160,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aacfda', endColorstr='#2270a0', GradientType=1 );
}
.h85{
    height: 85vh !important;
    overflow-y: auto;
}
.h35{
    height: 35 !important;
    overflow-y: auto;
}

.header-dashboard{
    background-color: white;
    padding: 2rem;
}
.card-header-dashboard{
    background-color: $secundario;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
    color: $celeste-dark;
}
