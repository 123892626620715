@import "./configs/color.css";

.react-time-picker__wrapper {
   display: block;
   width: 100%;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   background-color: #fff;
   background-clip: padding-box;
   border-radius: 1em;
   border: 0.1em solid #aacfda;
   font-size: 1rem;
    line-height: 1.5;
    font-family: 'Dosis', sans-serif !important;
    font-weight: normal !important;
}
.react-time-picker__inputGroup__input:invalid {
   background: rgba(255, 0, 0, 0.2);
}

.table-detalle tr:last-child {
   border-bottom: solid 1px red;
}


.titulo-dias.table thead th, .table th, .titulo-dias.table td{
   /* border-top: 1.3px solid #014955 !important;
   border-bottom: 1.3px solid #014955; */
}
.titulo-tabla{
   /* border-top: 1.3px solid #014955 !important;
   border-bottom: 1.3px solid #014955; */
}


.react-date-picker__wrapper {
   width: 100%;
   height: calc(2.25rem + 2px);
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid $secundario;
   border-radius: 0.25rem;
}
.react-date-picker
{
   width: 100%;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
   padding: 1em 0.5em;
}
.react-calendar{
   font-size: 13px;
}
.react-calendar__tile--active {
   background: $secundario;
   color: white;
}


.react-date-picker__calendar {
   max-width: 80vw;
   position: absolute;
}
