@import './configs/color.css';

.card-ingreso{
    border: 1px solid $secundario;
    -webkit-box-shadow: 2px 6px 20px -2px rgba(199,199,199,1);
    -moz-box-shadow: 2px 6px 20px -2px rgba(199,199,199,1);
    box-shadow: 2px 6px 20px -2px rgba(199,199,199,1);
}

.card-ingreso .header-azul{
    height: 15px;
    background: rgba(32,112,158,1) !important;
    background: -moz-linear-gradient(left, rgba(32,112,158,1) 0%, rgba(170,207,218,1) 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(32,112,158,1)), color-stop(100%, rgba(170,207,218,1))) !important;
    background: -webkit-linear-gradient(left, rgba(32,112,158,1) 0%, rgba(170,207,218,1) 100%) !important;
    background: -o-linear-gradient(left, rgba(32,112,158,1) 0%, rgba(170,207,218,1) 100%) !important;
    background: -ms-linear-gradient(left, rgba(32,112,158,1) 0%, rgba(170,207,218,1) 100%) !important;
    background: linear-gradient(to right, rgba(32,112,158,1) 0%, rgba(170,207,218,1) 100%)  !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20709e', endColorstr='#aacfda', GradientType=1 ) !important;
}
.card-ingreso .header-verde{
    height: 15px;
    background: rgba(169,186,58,1);
    background: -moz-linear-gradient(left, rgba(169,186,58,1) 0%, rgba(170,207,218,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(169,186,58,1)), color-stop(100%, rgba(170,207,218,1)));
    background: -webkit-linear-gradient(left, rgba(169,186,58,1) 0%, rgba(170,207,218,1) 100%);
    background: -o-linear-gradient(left, rgba(169,186,58,1) 0%, rgba(170,207,218,1) 100%);
    background: -ms-linear-gradient(left, rgba(169,186,58,1) 0%, rgba(170,207,218,1) 100%);
    background: linear-gradient(to right, rgba(169,186,58,1) 0%, rgba(170,207,218,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a9ba3a', endColorstr='#aacfda', GradientType=1 );
}
