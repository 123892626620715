@page {
    size: portrait;
    margin: 0 0 0 0;
}
.print-only {
    display: none !important;
}

@media print {
    .sidebar,
    .topnavbar,
    .offsidebar,
    .nav-justified, .nav-tabs.nav-justified,
    .toolbar,
    .react-tabs__tab-list,
    .rc-tabs-bar,
    footer,
    .btn {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }
    .react-tabs__tab-panel--selected{
        border: none !important;
    }
    .react-tabs__tab-panel--selected{
        margin: 0 !important;
        padding: 0 !important;
    }
    .content-wrapper {
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
    }
    .form-group{
        display: none !important;
    }
    .react-bs-table-container{
        display: none !important;
    }
    .fondo-inverso-navbar{
        display: none !important;
    }
    .no-print {
        display: none !important;
    }
    .print-only {
        display: block !important;
    }
    .print {
        display: block !important;
    }
    .contrato-container{
        padding: 0;
    }
    .no-print-margin{
        margin: 0 !important;
    }
    .no-print-padding{
        padding: 0 !important;
    }
    .react-bs-table-container .table-bordered{
        min-width: 2cm !important;
        max-width: 20cm !important;
    }
    .print-scale{
        width: 21.6cm;
        padding: .2cm;
        font-size: 14px;
    }
    .rc-tabs-tabpane{
      border: none !important;
    }
    .contrato{
      box-shadow: none !important;
    }
    body{
      background-color: white !important;
    }

    .print-big-text{
        font-size: 16px !important;
    }
    .print-medium-text{
        font-size: 11pt !important;
    }

    .print-small-text{
        font-size: 9.5pt !important;
    }

    .contenedor-detalle {
        width: 21.2cm;
        position: relative;
        min-height: 27.5cm;
        overflow: hidden;
        padding-left: 0.3cm !important;
        margin-bottom:0cm;
    }

    .print-margin-top-1{
        margin-top:1cm;
    }

    .print-padding-bottom-3{
        padding-bottom:3cm;
    }
    .tabla-impresion{
     max-width: 20cm;
    }

    .tabla-impresion td{
        padding: 3pt 5px 2pt 3px !important;
    }

    .vista-recibo{
        padding: 6mm !important;
        width: 100mm !important;
    }
}
.page-break {
  page-break-before: always !important;
}

.dont-break{
    page-break-inside: avoid;
}

.print-shadow {
    font-family: sans-serif;
    background-color: white;
    position: relative;
    -webkit-print-color-adjust: exact;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mb05 {
    margin-bottom: 0.5em;
}

.fnt-12 {
    font-size: 16px !important;
}
.fnt-14 {
    font-size: 20px !important;
}

.fnt-11 {
    font-size: 16px !important;
}
